<template>
  <div class="faq-navigation">
    <h2
      v-text="$trans('faq.titles.guide')"
      class="faq-navigation__title tw-mb-2"
    />

    <div class="faq-navigation__btn-wrap">
      <button
        class="btn tw-font-medium tw-w-full faq-navigation__btn"
        @click="open = !open"
      >
        <div class="tw-flex tw-justify-between">
          {{ currentQuestion.title }}
          <ui-material-icon
            :name="open ? 'arrow_drop_up': 'arrow_drop_down'"
            data-test="arrow-icon"
          />
        </div>
      </button>
    </div>

    <nav
      :class="{ open }"
    >
      <ul>
        <li
          v-for="(questionObject, k) in questions"
          :key="k"
        >
          <UiLink
            :class="{
              active: question === questionObject.slug
            }"
            href="#"
            role="button"
            class="tw-flex tw-w-full"
            data-test="link"
            @click.prevent="select(questionObject)"
          >
            <span>
              {{ questionObject.title }}
            </span>
          </UiLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - faqNavigation
   * @params {Array} questions - List of questions
   * @params {string} question
   */
  export default {
    name: 'FaqNavigation',
    props: {
      questions: {
        type: Array,
        required: true
      },
      question: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      /**
       * @function currentQuestion
       * @returns {object}
       */
      currentQuestion () {
        return this.questions.find(q => q.slug === this.question)
      }
    },
    methods: {
      /**
       * @function select
       */
      select (question) {
        this.open = false
        EventBus.$emit('faq:pick-question', question)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .faq-navigation {
    &__title {
      font-size: 20px;
      padding: 0 30px;
    }

    &__btn {
      background-color: black;
      color: white;
      min-height: 45px;

      &-wrap {
        display: none;
        padding: 8px 30px;

        @media only screen and (max-width: 857px) {
          display: flex;
        }
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(black, 0.5) !important;
      }
    }

    nav {
      &.open {
        display: block;
        padding: 0 16px;
      }

      @media only screen and (max-width: 857px) {
        display: none;
      }
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    a {
      min-height: 55px;
      padding: 8px 30px;
      color: $secondary-text;
      background-color: white;
      text-decoration: none;
      border-bottom: 1px solid $divider;

      &:hover {
        background-color: lighten($light-gray, 2%);
      }

      &.active {
        color: $primary-text;
        background-color: $light-gray;
        font-weight: 500;
      }

      & > span {
        margin: auto 0;
      }
    }

    li:first-child a {
      border-top: 1px solid $divider;
    }

    @media only screen and (max-width: 857px) {
      &__title {
        padding: 0 16px;
      }

      &__btn-wrap,
      a {
        padding: 8px 16px;
      }
    }
  }

</style>
