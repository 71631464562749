<template>
  <div class="faq-documents tw-mt-4 tw-mb-12">
    <h2
      v-text="$trans('faq.titles.documents')"
      class="faq-documents__title tw-mb-2"
    />

    <div class="faq-documents__list">
      <faq-document
        v-for="(document, k) in documents"
        :key="k"
        :title="document.title"
        :format="document.format"
        :link="document.link"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import FaqDocument from './_subs/FaqDocument/index.vue'

  /**
   * @module component - faqDocuments
   */
  export default defineComponent({
    name: 'FaqDocuments',
    components: {
      FaqDocument
    },
    computed: {
      /**
       * @function documents
       * @returns {Array<{title: string, format: string, link: string}>}
       */
      documents () {
        return [
          {
            title: this.$trans('faq.values.delivery_order'),
            format: 'PDF',
            link: 'https://s3-eu-west-1.amazonaws.com/ct-documents/fr_chronotruck_delivery_order.pdf'
          }
        ]
      }
    }
  })
</script>

<style lang="scss" scoped>

  .faq-documents {
    &__title {
      font-size: 20px;
      padding: 0 30px;
    }

    .faq-document {
      border-bottom: 1px solid $divider;

      &:first-child {
        border-top: 1px solid $divider;
      }
    }

    @media only screen and (max-width: 857px) {
      &__title {
        padding: 0 16px;
      }
    }
  }

</style>
