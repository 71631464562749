<template>
  <div class="faq-document tw-flex">
    <div
      class="p-0 tw-flex tw-flex-1 tw-flex-col"
    >
      <h3
        class="faq-document__title tw-text-sm tw-font-normal"
        v-text="title"
      />

      <span
        class="tw-text-gray-700 faq-document__format"
      >
        {{ $trans('faq.labels.format', { format }) }}
      </span>
    </div>
    <div
      class="tw-flex"
    >
      <ui-button
        :href="link"
        variant="link"
        size="sm"
        target="_blank"
        rel="noopener"
        class="faq-document__download tw-ml-auto"
      >
        {{ $trans('faq.buttons.download') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - faqDocument
   * @param {string} title
   * @param {string} format
   * @param {string} link
   */
  export default {
    name: 'FaqDocument',
    props: {
      title: {
        type: String,
        required: true
      },
      format: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .faq-document {
    padding: 20px 30px;
    background: white;

    &__format {
      font-style: italic;
    }

    &__download {
      margin: auto 0 auto auto;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: inline-block;
        width: 100%;
      }
    }

    &__title {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 857px) {
      padding: 20px 16px;
    }
  }

</style>
