<template>
  <div class="faq-contact tw-mt-4 tw-mb-4 md:tw-mb-12">
    <h2
      v-text="$trans('faq.titles.question')"
      class="faq-contact__title tw-mb-2"
      data-test="title"
    />

    <div class="faq-contact__content tw-flex tw-items-center">
      <p
        v-text="$trans('faq.paragraphs.contact')"
        class="tw-mb-0 tw-text-secondary-text tw-mr-2 tw-flex-1"
        data-test="content"
      />
      <ui-button
        :href="link"
        size="sm"
        variant="info"
        rel="noopener"
        target="_blank"
        data-test="link"
      >
        {{ $trans('faq.buttons.contact') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - FaqContact
   */
  export default defineComponent({
    name: 'FaqContact',
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * Wrapping the locale inside a computed property to be able
       * to mock it in the unit tests.
       * @function locale
       * @returns {string}
       */
      locale () {
        return this.$i18n.locale
      },
      /**
       * @function link
       * @returns {string}
       */
      link () {
        const links = chronoinfos.forms.contact.faq

        return links[this.isUserShipper ? 'shipper' : 'carrier'][this.locale === 'fr' ? 'fr' : 'other']
      }
    }
  })
</script>

<style lang="scss" scoped>

  .faq-contact {
    &__title {
      font-size: 20px;
      padding: 0 30px;
    }

    &__content {
      padding: 20px 30px;
      border-bottom: 1px solid $divider;
      border-top: 1px solid $divider;
    }

    @media only screen and (max-width: 857px) {
      &__title {
        padding: 0 16px;
      }

      &__content {
        padding: 20px 16px;
      }
    }
  }

</style>
